import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { type ReactNode, type FunctionComponent } from "react";
import { type IconType } from "react-icons";
import {
  BsArrowRight,
  BsCheckCircle,
  BsHouse,
  BsPencil,
  BsPerson,
  BsStarFill,
  BsWallet2,
} from "react-icons/bs";
import { PmFaqs, RenterFaqs } from "../services/Faqs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MessageModal from "../components/MessageModal";

interface PanelContainerProps {
  backgroundColor: string;
  children: ReactNode;
}

const PanelContainer: FunctionComponent<PanelContainerProps> = observer(
  function PanelContainer({ backgroundColor, children }) {
    return (
      <Box w="100vw" backgroundColor={backgroundColor}>
        <Container maxW="container.xl">{children}</Container>
      </Box>
    );
  }
);

const Panel0: FunctionComponent = observer(function Panel0() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PanelContainer backgroundColor="white">
      <Wrap direction={["column", "row"]} justify="center" mb="60px">
        <WrapItem>
          <VStack
            maxW={{ base: "300px", sm: "400px", md: "600px" }}
            minH={{ base: "0", md: "100%" }}
            align="start"
          >
            <Spacer />
            <Box>
              <Icon
                as={BsStarFill}
                boxSize={5}
                color="rgba(252, 184, 35, 1)"
                mx="2px"
              />
              <Icon
                as={BsStarFill}
                boxSize={5}
                color="rgba(252, 184, 35, 1)"
                mx="2px"
              />
              <Icon
                as={BsStarFill}
                boxSize={5}
                color="rgba(252, 184, 35, 1)"
                mx="2px"
              />
              <Icon
                as={BsStarFill}
                boxSize={5}
                color="rgba(252, 184, 35, 1)"
                mx="2px"
              />
              <Icon
                as={BsStarFill}
                boxSize={5}
                color="rgba(252, 184, 35, 1)"
                mx="2px"
              />
            </Box>
            <Text fontSize="md" color="gray.700">
              Trusted by 10,000+ residents and property managers
            </Text>
            <Heading size="3xl" my="20px" maxWidth="500px">
              Helping renters get approved
            </Heading>
            <Text fontSize="lg" color="gray.700">
              We help property managers boost occupancy rates and increase
              revenue while expanding housing options for renters.
            </Text>
            <Button
              rightIcon={<Icon as={BsArrowRight} />}
              colorScheme="blue"
              fontWeight="extrabold"
              mt="20px"
              px="20px"
              onClick={onOpen}
            >
              Work with Us
            </Button>
            <Spacer />
          </VStack>
        </WrapItem>
        {/* <Spacer /> */}
        <WrapItem>
          <HStack
            w={{ base: "100%", sm: "400px", md: "600px" }}
            h={{ base: "352px", md: "600px" }}
          >
            <Image
              src="/images/home/vertical0.png"
              w={{ base: "100px", md: "170px" }}
            />
            <Spacer />
            <Image
              src="/images/home/vertical1.png"
              w={{ base: "100px", md: "170px" }}
              mt="100px"
            />
            <Spacer />
            <Image
              src="/images/home/vertical2.png"
              w={{ base: "100px", md: "170px" }}
            />
          </HStack>
        </WrapItem>
      </Wrap>

      <MessageModal isOpen={isOpen} onClose={onClose} />
    </PanelContainer>
  );
});

const CircleWitCharacter: FunctionComponent<{
  character: string;
  backgroundColor: string;
}> = observer(function CircleWitCharacter({ character, backgroundColor }) {
  return (
    <Box borderRadius="50%" h="80px" w="80px" backgroundColor={backgroundColor}>
      <Flex align="center" justify="center" h="100%" w="100%">
        <Text fontWeight="semibold" fontSize="36px">
          {character}
        </Text>
      </Flex>
    </Box>
  );
});

const Panel1: FunctionComponent = observer(function Panel1() {
  return (
    <PanelContainer backgroundColor="rgba(185, 229, 252, 1)">
      <VStack justifyContent="center" paddingY="100px">
        <Text fontSize="4xl" fontWeight={900} textAlign="center">
          How it works
        </Text>
        <Text mt="10px" maxW="600px" textAlign="center">
          We&apos;ve made it simple for property managers to increase occupancy
          rates while decreasing default risk.
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} spacing="40px">
          <Box
            marginTop="40px"
            padding="40px"
            h="350px"
            w="280px"
            backgroundColor="white"
            borderRadius="8px"
            border="1px"
            borderColor="gray.300"
          >
            <CircleWitCharacter character="1" backgroundColor="#E8D4FE" />
            <Text paddingTop="40px" fontWeight="semibold" fontSize="30px">
              Sign Up
            </Text>
            <Text paddingTop="15px" fontSize="16px" color="gray.700">
              Register your property with Cosign
            </Text>
          </Box>
          <Box
            marginTop="40px"
            padding="40px"
            h="350px"
            w="280px"
            backgroundColor="white"
            borderRadius="8px"
            border="1px"
            borderColor="gray.300"
          >
            <CircleWitCharacter character="2" backgroundColor="#FDCCD2" />
            <Text paddingTop="40px" fontWeight="semibold" fontSize="30px">
              Refer
            </Text>
            <Text paddingTop="15px" fontSize="16px" color="gray.700">
              Let renters who fall below your criteria know that Cosign can
              help.
            </Text>
          </Box>
          <Box
            marginTop="40px"
            padding="40px"
            h="350px"
            w="280px"
            backgroundColor="white"
            borderRadius="8px"
            border="1px"
            borderColor="gray.300"
          >
            <CircleWitCharacter character="3" backgroundColor="#EAFAC9" />
            <Text paddingTop="40px" fontWeight="semibold" fontSize="30px">
              Application
            </Text>
            <Text paddingTop="15px" fontSize="16px" color="gray.700">
              We take care of the screening and stand behind renters we approve.
            </Text>
          </Box>
          <Box
            marginTop="40px"
            padding="40px"
            h="350px"
            w="280px"
            backgroundColor="white"
            borderRadius="8px"
            border="1px"
            borderColor="gray.300"
          >
            <CircleWitCharacter character="4" backgroundColor="#CEF9FD" />
            <Text paddingTop="40px" fontWeight="semibold" fontSize="30px">
              Guarantee
            </Text>
            <Text paddingTop="15px" fontSize="16px" color="gray.700">
              If approved, we cover rent and damages up to 3x-12x the rent,
              based on selected plan.
            </Text>
          </Box>
        </SimpleGrid>
      </VStack>
    </PanelContainer>
  );
});

interface Panel2StepProps {
  icon: IconType;
  title: string;
  description: string;
}

const Panel2Step: FunctionComponent<Panel2StepProps> = observer(
  function Panel2Step({ icon, title, description }) {
    return (
      <Box marginTop="40px" h="170px" w="268px">
        <Box
          w="64px"
          h="64px"
          p="16px"
          borderRadius="8px"
          backgroundColor="#EFF8FE"
        >
          <Icon as={icon} boxSize="32px" color="#069CDF" />
        </Box>
        <Text paddingTop="10px" fontWeight="semibold" fontSize="20px">
          {title}
        </Text>
        <Text fontSize="16px" color="gray.700">
          {description}
        </Text>
      </Box>
    );
  }
);

const Panel2: FunctionComponent = observer(function Panel2() {
  return (
    <PanelContainer backgroundColor="white">
      <VStack justifyContent="cetner" paddingX="10px" paddingY="100px">
        <Text
          fontSize="20px"
          fontWeight="extrabold"
          color="#0283C5"
          textAlign="center"
        >
          For Renters
        </Text>
        <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
          A seamless process every step of the way
        </Text>
        <Text
          fontSize="18px"
          fontWeight="normal"
          color="gray.700"
          textAlign="center"
          maxW="675px"
        >
          As your co-signer, no matter if you&apos;re short on income, have
          less-than-perfect credit, or have had a hiccup in your rental history,
          we can help get you into your new home.
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 4 }}
          spacing="40px"
          justifyContent="center"
          paddingTop="20px"
        >
          <Panel2Step
            icon={BsPerson}
            title="Get Referred"
            description="Get referred by participating properties."
          />
          <Panel2Step
            icon={BsPencil}
            title="Apply"
            description="Apply at a participating property and get referred if you don't qualify"
          />
          <Panel2Step
            icon={BsWallet2}
            title="Pay Fee"
            description="Pay a 1-time fee and let us guarantee your lease."
          />
          <Panel2Step
            icon={BsHouse}
            title="Move In"
            description="Get your approval letter and move in!"
          />
        </SimpleGrid>
      </VStack>
    </PanelContainer>
  );
});

interface PanelTestimonialProps {
  name: string;
  src: string;
  role: string;
  quote: string;
}

const Panel3Testimonial: FunctionComponent<PanelTestimonialProps> = observer(
  function Panel3Testimonial({ name, src, role, quote }) {
    return (
      <Box
        padding="20px"
        minW="300px"
        maxW="500px"
        backgroundColor="white"
        borderRadius="8px"
        border="1px"
        borderColor="gray.300"
      >
        <Text fontWeight="normal" fontSize="16px" color="gray.700">
          &quot;{quote.trim()}&quot;
        </Text>
        <HStack marginTop="30px">
          <Avatar size="lg" name={name} src={src} bg="gray.300" />
          <Box paddingLeft="10px">
            <Text fontWeight="semibold" fontSize="20px" color="black">
              {name}
            </Text>
            <Text fontWeight="normal" fontSize="16px" color="gray.500">
              {role}
            </Text>
          </Box>
        </HStack>
      </Box>
    );
  }
);

const Panel3: FunctionComponent = observer(function Panel3() {
  return (
    <PanelContainer backgroundColor="rgba(185, 229, 252, 1)">
      <VStack justifyContent="cetner" paddingX="10px" paddingY="100px">
        <Text
          fontSize="20px"
          fontWeight="extrabold"
          color="#464D59"
          textAlign="center"
        >
          Testimonials
        </Text>
        <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
          Properties & Renters love Cosign
        </Text>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing="60px"
          verticalAlign="center"
          justify="center"
          align="center"
          marginTop="40px"
        >
          <Box>
            <Panel3Testimonial
              name="Orla Flores"
              src="/images/home/pm_testimonial0.png"
              role="Property Manager"
              quote={`
                I have been using Cosign in my portfolio for over a year. They are
                very easy to use - you just refer your applicant to Cosign and they
                take it from there. There's also no cost to the property. Highly
                recommend!
              `}
            />
          </Box>
          <Box verticalAlign="center">
            <Flex verticalAlign="center">
              <Panel3Testimonial
                name="Alicia Martin"
                src="/images/home/renter_testimonial0.png"
                role="Renter"
                quote="Cosign helped me move-in after being declined from over 20 properties!"
              />
            </Flex>
          </Box>
        </Stack>
      </VStack>
    </PanelContainer>
  );
});

const Panel4: FunctionComponent = observer(function Panel4() {
  return (
    <PanelContainer backgroundColor="white">
      <VStack justifyContent="cetner" paddingX="10px" paddingTop="100px">
        <Text
          fontSize="20px"
          fontWeight="extrabold"
          color="#0283C5"
          textAlign="center"
        >
          For Property Managers
        </Text>
        <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
          Approve more applicants
        </Text>
        <Text
          fontSize="18px"
          fontWeight="normal"
          color="gray.700"
          textAlign="center"
          maxW="675px"
        >
          We help increase your occupancy rates while decreasing default risks
          by taking on the financial risk so you can confidently approve more
          renters.
        </Text>
        <Box
          marginTop="40px"
          paddingTop="16px"
          paddingX="16px"
          borderTopRadius="16px"
          backgroundColor="black"
          w="100%"
        >
          <Image src="/images/home/pm_portal.png" padding="0" />
        </Box>
      </VStack>
    </PanelContainer>
  );
});

const Panel5: FunctionComponent = observer(function Panel5() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PanelContainer backgroundColor="rgba(185, 229, 252, 1)">
      <VStack justifyContent="cetner" paddingX="10px" paddingY="80px">
        <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
          Join Cosign
        </Text>
        <Text
          fontSize="18px"
          fontWeight="normal"
          color="gray.700"
          textAlign="center"
          maxW="675px"
        >
          Sign up as a property manager or apply as a resident. We&apos;re here
          to help.
        </Text>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "30px", lg: "60px" }}
          marginTop="20px"
        >
          <HStack>
            <Icon as={BsCheckCircle} boxSize={6} />
            <Text fontWeight="extrabold" fontSize="24px" whiteSpace="nowrap">
              Free For Properties
            </Text>
          </HStack>
          <HStack>
            <Icon as={BsCheckCircle} boxSize={6} />
            <Text fontWeight="extrabold" fontSize="24px" whiteSpace="nowrap">
              100% Default Guarantee
            </Text>
          </HStack>
          <HStack>
            <Icon as={BsCheckCircle} boxSize={6} />
            <Text fontWeight="extrabold" fontSize="24px" whiteSpace="nowrap">
              24/7 Support
            </Text>
          </HStack>
        </Stack>
        <Button
          rightIcon={<Icon as={BsArrowRight} />}
          colorScheme="blue"
          fontWeight="extrabold"
          marginTop="40px"
          px="20px"
          onClick={onOpen}
        >
          Work with Us
        </Button>
      </VStack>

      <MessageModal isOpen={isOpen} onClose={onClose} />
    </PanelContainer>
  );
});

interface Panel6QuestionProps {
  question: string;
  answer: ReactNode;
}

const Panel6Question: FunctionComponent<Panel6QuestionProps> = observer(
  function Panel6Question({ question, answer }) {
    return (
      <Box marginX="auto" maxW="500px">
        <Text fontWeight="semibold" fontSize="18px">
          {question.trim()}
        </Text>
        <Box
          fontWeight="normal"
          fontSize="16px"
          paddingTop="10px"
          color="gray.700"
        >
          {answer}
        </Box>
      </Box>
    );
  }
);

const Panel6: FunctionComponent = observer(function Panel6() {
  return (
    <PanelContainer backgroundColor="white">
      <VStack justifyContent="cetner" paddingX="10px" paddingTop="100px">
        <Text
          fontSize="20px"
          fontWeight="extrabold"
          color="#0283C5"
          textAlign="center"
        >
          FAQs
        </Text>
        <Text fontSize="36px" fontWeight="extrabold" textAlign="center">
          Frequently Asked Questions
        </Text>
        <Tabs isFitted w="100%">
          <TabList>
            <Tab>Renters</Tab>
            <Tab>Property Managers</Tab>
          </TabList>
          <TabPanels marginTop="20px">
            <TabPanel>
              <VStack justifyContent="cetner" w="100%">
                <SimpleGrid
                  columns={{ base: 1, lg: 2 }}
                  spacing="40px"
                  w="100%"
                >
                  {[
                    RenterFaqs[0],
                    RenterFaqs[4],
                    RenterFaqs[1],
                    RenterFaqs[3],
                    RenterFaqs[2],
                    RenterFaqs[5],
                  ].map((faq) => (
                    <Panel6Question
                      key={faq.question}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  ))}
                </SimpleGrid>

                <a href="/faqs/renters">
                  <Button colorScheme="gray" variant="outline" marginTop="20px">
                    View More
                  </Button>
                </a>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack justifyContent="cetner" w="100%">
                <SimpleGrid
                  columns={{ base: 1, lg: 2 }}
                  spacing="40px"
                  w="100%"
                >
                  {[
                    PmFaqs[0],
                    PmFaqs[2],
                    PmFaqs[5],
                    PmFaqs[1],
                    PmFaqs[3],
                    PmFaqs[4],
                  ].map((faq) => (
                    <Panel6Question
                      key={faq.question}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  ))}
                </SimpleGrid>

                <a href="/faqs/property_managers">
                  <Button colorScheme="gray" variant="outline" marginTop="20px">
                    View More
                  </Button>
                </a>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </PanelContainer>
  );
});

const IndexPage: FunctionComponent = observer(function IndexPage() {
  return (
    <>
      <Header />
      <Panel0 />
      <Panel1 />
      <Panel2 />
      <Panel3 />
      <Panel4 />
      <Panel5 />
      <Panel6 />
      <Footer />
    </>
  );
});

export default IndexPage;
